<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";

export default {
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            items: [
                {
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Pre-Orders List",
                    active: true,
                },
            ],
            active_tab_name: 'under_review',
            tabs: [
                {
                    title: 'Under Review',
                    name: 'pre_orders_under_review_list',
                    status_key: 'under_review',
                    icon: 'bx bx-time',
                    color: 'warning'
                },
                {
                    title: 'Approved',
                    name: 'pre_orders_approved_list',
                    status_key: 'approved',
                    icon: 'bx bx-check',
                    color: 'info'
                },
                {
                    title: 'Completed',
                    name: 'pre_orders_completed_list',
                    status_key: 'completed',
                    icon: 'bx bx-heart',
                    color: 'success'
                },
                {
                    title: 'Cancelled',
                    name: 'pre_orders_cancelled_list',
                    status_key: 'cancelled',
                    icon: 'bx bx-x',
                    color: 'danger'
                }
            ],
            tab_statuses: {}
        }
    },
    computed: {
        headerTitle() {
            switch (this.$route.name) {
                case "pre_orders_under_review_list":
                    return "Pre-Orders Under Review List"
                case "pre_orders_approved_list":
                    return "Pre-Orders Approved List"
                case "pre_orders_completed_list":
                    return "Pre-Orders Completed List"
                case "pre_orders_cancelled_list":
                    return "Pre-Orders Cancelled List"
                case "pre_orders_container_update":
                    return "Edit Container Pre-Order"
                case "pre_orders_wagon_update":
                    return "Edit Wagon Pre-Order"
                case "pre_orders_empty_wagon_update":
                    return "Edit Empty Wagon Pre-Order"
                default:
                    return "Pre-Orders List"
            }
        }
    },
    methods: {
        async changeTab(tab) {
            await this.$router.push({ name: tab.name })
            await this.getTabStatuses()
        },
        async getTabStatuses() {
            try {
                let response = await axios.get('/pre_order/statuses/')
                this.tab_statuses = response.data
            } catch {
                this.tab_statuses = {}
            }
        }
    },
    created() {
        this.getTabStatuses()
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="headerTitle" :items="items" />
        <b-row v-if="tabs.map(tab => tab.name).includes($route.name)">
            <b-col xl="3" sm="6" v-for="tab in tabs" :key="`tab_` + tab.name">
                <b-card no-body class="cursor-pointer" @click="changeTab(tab)"
                    :class="$route.name === tab.name ? `border border-${tab.color} shadow-lg` : ''"
                    :style="{ transform: 'scale(' + ($route.name === tab.name ? 1.05 : 1) + ')' }" style="transition: 0.3s">
                    <b-card-body>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h6 class="text-muted mb-3">{{ tab.title }}</h6>
                                <h2 class="mb-0" :id="tab.name + '-e-tab'">
                                    {{ tab_statuses[tab.status_key] ? tab_statuses[tab.status_key] : 0 }}
                                </h2>
                            </div>
                            <div class="flex-shrink-0 avatar-sm">
                                <div class="avatar-title fs-22 rounded" :class="`bg-soft-${tab.color} text-${tab.color}`">
                                    <i :class="`bx bx-${tab.icon}`"></i>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <router-view></router-view>
    </Layout>
</template>